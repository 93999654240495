import logo from './logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <div>
        {/* <img src={ require('./logo.jpeg').default } alt="logo" style={{maxWidth: '300px'}} /> */}
        <img src={logo} alt="logo" style={{maxWidth: '300px'}} />
        {/* <img src={process.env.PUBLIC_URL + '/logo.jpeg'} alt="logo" style={{maxWidth: '300px'}} /> */}
      </div>
        <h1 style={{ marginTop: 30 }}>
          Coming Soon!
        </h1>
    </div>
  );
}

export default App;
